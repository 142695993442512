$(document).ready(function ($) {
	$('.sh-trig-more').each(function() {
		var trigger = $(this),
			content = $('#' + trigger.data('content')),
			reverseContent = $('#' + trigger.data('reverse-content'));

		trigger.on('click', function (e) {
			e.preventDefault();
			if (content.is(':hidden')) {
				trigger.hide();
				content.slideDown(200, function () {
				});
			}
		});
	});
});

(function ($) {
	$.fn.toggler = function (settings) {

		settings = jQuery.extend({
			showSpeed: 100,
			hideSpeed: 100,
			hideTrigger: false,
			slide: true,
			scrollToContent: false,
			offsetScroll: 0,
			hideOnDocumentClick: false,
			documentClickElement: '',
			hideOnEscape: false,
			checkboxReverse: false
		}, settings);

		$(this).each(function () {

			var trigger = $(this),
				content = $('#' + trigger.data('content')),
				reverseContent = $('#' + trigger.data('reverse-content'));

			// Radio nebo checkbox
			if (trigger.is(':radio') || trigger.is(':checkbox')) {
				var name = trigger.attr('name');

				$('[name=' + name + ']').on('change', function () {
					content.hide();
				});
				trigger.on('change', function () {
					var checked = $(this).is(':checked');
					if(settings.checkboxReverse) checked = !checked;
					if (checked) {
						content.fadeIn(settings.showSpeed);
					} else {
						content.hide();
					}
				});
				$(window).on('load', function () {
					if (trigger.is(':checked')) {
						content.fadeIn(settings.showSpeed);
					}
				});
			}
			// Standardní element
			else {
				trigger.on('click', function (e) {
					e.preventDefault();
					if (content.is(':hidden')) {
						if (settings.slide) {
							content.slideDown(settings.showSpeed, function () {
								if (settings.scrollToContent) {
									scrollToContent();
								}
							});
							if(reverseContent.length) {
								reverseContent.slideUp(settings.showSpeed);
							}
						} else {
							content.fadeIn(settings.showSpeed, function () {
								if (settings.scrollToContent) {
									scrollToContent();
								}
							});
							if(reverseContent.length) {
								reverseContent.fadeIn(settings.showSpeed);
							}
						}

						$(this).addClass('active').find('.text').text($(this).attr('data-less'));

						if (settings.hideTrigger) {
							trigger.hide();
						}
					} else {
						if (settings.slide) {
							content.slideUp(settings.hideSpeed, function () {
								if (settings.scrollToContent) {
									scrollToTrigger();
								}
							});
							if(reverseContent.length) {
								reverseContent.slideDown(settings.hideSpeed);
							}
						} else {
							content.fadeOut(settings.hideSpeed, function () {
								if (settings.scrollToContent) {
									scrollToTrigger();
								}
							});
							if(reverseContent.length) {
								reverseContent.fadeIn(settings.hideSpeed);
							}
						}
						$(this).removeClass('active').find('.text').text($(this).attr('data-more'));

						if (settings.hideTrigger) {
							$('[data-content=' + trigger.attr('data-content') + ']').fadeIn(settings.hideSpeed);
						}
					}
					return false;
				});
			}
		
		});
	};
})(jQuery);
