jQuery(function(){
	initModals('.modal-trigger');
});

function initGalleries(selector) {
	const lightbox = GLightbox({
		selector: selector,
		loop: true,
		zoomable: false,
		openEffect: 'fade',
		closeEffect: 'fade',
	});
}

function initModals(selector) {
	let customSlideHTML = `<div class="gslide">
		<div class="gslide-inner-content">
			<div class="ginner-container">
				<div class="gslide-media">
					<button class="gclose gbtn" aria-label="Zavřít" data-taborder="3"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" xml:space="preserve"><g><g><path d="M505.943,6.058c-8.077-8.077-21.172-8.077-29.249,0L6.058,476.693c-8.077,8.077-8.077,21.172,0,29.249C10.096,509.982,15.39,512,20.683,512c5.293,0,10.586-2.019,14.625-6.059L505.943,35.306C514.019,27.23,514.019,14.135,505.943,6.058z"></path></g></g><g><g><path d="M505.942,476.694L35.306,6.059c-8.076-8.077-21.172-8.077-29.248,0c-8.077,8.076-8.077,21.171,0,29.248l470.636,470.636c4.038,4.039,9.332,6.058,14.625,6.058c5.293,0,10.587-2.019,14.624-6.057C514.018,497.866,514.018,484.771,505.942,476.694z"></path></g></g></svg></button>
				</div>
			</div>
		</div>
	</div>`;

	const modalsTriggers = GLightbox({
		selector: selector,
		openEffect: 'fade',
		closeEffect: 'fade',
		skin: 'clean glightbox-modal',
		height: 'auto',
		slideHTML: customSlideHTML,
		closeButton: false
	});

	modalsTriggers.on('open', () => {
		const closeBtns = document.querySelectorAll('.gclose');
		if(!closeBtns.length) return;
		for (const closeBtn of closeBtns) {
			closeBtn.addEventListener('click', () => modalsTriggers.close());
		}
	});
	
	const modalsTriggerEls = document.querySelectorAll(selector);
	for(const modalsTrigger of modalsTriggerEls) {
		if(!modalsTrigger.classList.contains('modal-trigger-auto')) return;

		const id = modalsTrigger.getAttribute('data-id');	

		// Set a cookie on close
		modalsTriggers.on('close', () => {
			$.cookie(id, 'true', { path: '/', expires: 1 })
		});

		modalsTriggers.on('open', () => {
			const links = document.querySelectorAll('.modal-leave');
			for (const link of links) {
				link.addEventListener('click', () => {
					$.cookie(id, 'true', { path: '/', expires: 1 })
				});
			}
		});

		// Show notification if no cookies are set
		if ($.cookie(id) != 'true') {
			modalsTrigger.dispatchEvent(new Event('click'));
		}
	}
}